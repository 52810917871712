import React from 'react';

const SendIcon = (): JSX.Element => {
  return (
    <svg
      width='30'
      height='26'
      viewBox='0 0 30 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.83922 11.3029L19.7092 3.94019C21.4732 3.17324 27.4554 0.718993 27.4554 0.718993C27.4554 0.718993 30.2165 -0.354741 29.9864 2.2529C29.9097 3.32663 29.2961 7.0847 28.6826 11.1495L26.7652 23.1907C26.7652 23.1907 26.6118 24.9547 25.308 25.2615C24.0041 25.5683 21.8567 24.1877 21.4732 23.881C21.1664 23.6509 15.7211 20.1996 13.727 18.5123C13.1901 18.0521 12.5766 17.1318 13.8037 16.058C16.5647 13.5271 19.8626 10.3826 21.8567 8.38852C22.777 7.46817 23.6974 5.32071 19.8626 7.92835L9.04858 15.2144C9.04858 15.2144 7.82145 15.9813 5.5206 15.2911C3.21974 14.6008 0.535405 13.6805 0.535405 13.6805C0.535405 13.6805 -1.30528 12.5301 1.83922 11.3029Z'
        fill='white'
      />
    </svg>
  );
};

export default SendIcon;

import React from 'react';

const TwitterIcon = (): JSX.Element => {
  return (
    <svg
      width='30'
      height='25'
      viewBox='0 0 30 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30 2.88563C28.8844 3.375 27.6956 3.69937 26.4562 3.85687C27.7312 3.09562 28.7044 1.89937 29.1619 0.4575C27.9731 1.16625 26.6606 1.66688 25.2619 1.94625C24.1331 0.744375 22.5244 0 20.7694 0C17.3644 0 14.6231 2.76375 14.6231 6.15188C14.6231 6.63938 14.6644 7.10812 14.7656 7.55437C9.6525 7.305 5.12813 4.85438 2.08875 1.12125C1.55812 2.04188 1.24688 3.09562 1.24688 4.23C1.24688 6.36 2.34375 8.24813 3.97875 9.34125C2.99062 9.3225 2.02125 9.03563 1.2 8.58375C1.2 8.6025 1.2 8.62687 1.2 8.65125C1.2 11.64 3.33187 14.1225 6.1275 14.6944C5.62687 14.8313 5.08125 14.8969 4.515 14.8969C4.12125 14.8969 3.72375 14.8744 3.35062 14.7919C4.1475 17.2275 6.40875 19.0181 9.0975 19.0763C7.005 20.7131 4.34812 21.6994 1.47188 21.6994C0.9675 21.6994 0.48375 21.6769 0 21.615C2.72438 23.3719 5.95312 24.375 9.435 24.375C20.7525 24.375 26.94 15 26.94 6.87375C26.94 6.60187 26.9306 6.33938 26.9175 6.07875C28.1381 5.2125 29.1637 4.13063 30 2.88563Z'
        fill='white'
      />
    </svg>
  );
};

export default TwitterIcon;
